import { render, staticRenderFns } from "./MainSidebar.vue?vue&type=template&id=1f9610e8"
import script from "./MainSidebar.vue?vue&type=script&lang=js"
export * from "./MainSidebar.vue?vue&type=script&lang=js"
import style0 from "./MainSidebar.vue?vue&type=style&index=0&id=1f9610e8&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NestedSidebar: require('/workspace/components/bars/NestedSidebar.vue').default})
