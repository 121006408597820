import { render, staticRenderFns } from "./common.vue?vue&type=template&id=9a9461d4"
import script from "./common.vue?vue&type=script&lang=js"
export * from "./common.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MainNavbar: require('/workspace/components/bars/MainNavbar.vue').default,MainSidebar: require('/workspace/components/bars/MainSidebar.vue').default,DialogGlobalSearch: require('/workspace/components/dialogs/DialogGlobalSearch.vue').default,AuthenticationForm: require('/workspace/components/AuthenticationForm.vue').default,Footer: require('/workspace/components/Common/Footer.vue').default})
